import dynamic from "next/dynamic";

const Templates = {
	TemplateBuilder: dynamic(() => import("./Ellitoral/TemplateBuilder/index")),
	Nota: dynamic(() => import("./Ellitoral/Nota")),
	Video: dynamic(() => import("./Ellitoral/Video")),
	Seccion: dynamic(() => import("./Ellitoral/Seccion")),
	Tag: dynamic(() => import("./Ellitoral/Tag")),
	Tags: dynamic(() => import('./Ellitoral/Tags')),
	Autor: dynamic(() => import('./Ellitoral/Autor')),
	Autores: dynamic(() => import('./Ellitoral/Autores')),
	Galeria: dynamic(() => import('./Ellitoral/Galeria')),
	Fotos: dynamic(() => import("./Ellitoral/Fotos")),
	Videos: dynamic(() => import("./Ellitoral/Videos")),
	EdicionImpresa: dynamic(() => import("./Ellitoral/EdicionImpresa")),
	EdicionAnterior: dynamic(() => import("./Ellitoral/EdicionAnterior")),
	Titulos: dynamic(() => import("./Ellitoral/Titulos")),
	Necrologicas: dynamic(() => import("./Ellitoral/Necrologicas")),
	LayoutNecrologicas: dynamic(() => import('./Ellitoral/LayoutNecrologicas')),
	LayoutEdicionesAnteriores: dynamic(() => import('./Ellitoral/LayoutEdicionesAnteriores')),
	MegaTest: dynamic(() => import("./Ellitoral/MegaTest")),

};

export default Templates;
