const RichText = (props) => {
    let { text = '' } = props;
    if(text && text.includes('img-ellitoral.lilax.io/')){
        text = text.replace(/src="http(s?):\/\/img-ellitoral\.lilax\.io\//g, 'src="https://www.ellitoral.com/images/');
    };
    
    return (
        <div dangerouslySetInnerHTML={{__html: text}} />
    )
};

export default RichText;
