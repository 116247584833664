import styles from "./styles.module.css";
import React, {Fragment} from 'react';
import { 
  Instagram, 
  Facebook, 
  Twitter, 
  Whatssap, 
  YouTube, 
  Phone 
} from '@/icons/index'
import Image from "../Image/new";

const Footer = () => {
  const suplementsList = [
    {
      id: "1",
      name: "Campolitoral",
      src: "https://campolitoral.com.ar/",
    },
    {
      id: "2",
      name: "Revista Nosotros",
      src: "https://www.ellitoral.com/um/revista-nosotros",
    },
    {
      id: "4",
      name: "Clasificados",
      src: "https://clasificados.ellitoral.com/",
    },
    {
      id: "5",
      name: "CYD Litoral",
      src: "https://www.cableydiario.com/",
    },
    {
      id: "6",
      name: "Podcasts",
      src: "https://www.ellitoral.com/el-litoral-podcasts",
    },
    {
      id: "7",
      name: "Mirador Provincial",
      src: "https://www.miradorprovincial.com/",
    },
    {
      id: "8",
      name: "Viví Mejor",
      src: "http://www.vivimejorsf.com.ar/",
    },
    {
      id: "9",
      name: "Puerto Negocios",
      src: "https://puertonegocios.com/",
    },
    {
      id: "10",
      name: "Notife",
      src: "https://notife.com/",
    },
    {
      id: "11",
      name: "Educacion SF",
      src: "https://www.ellitoral.com/especiales/educacion-sf/estudiar-en-santa-fe",
    },
  ];

  const linksList = [
    {
      id: "1",
      name: "Hemeroteca Digital (1930-1979)",
      src: "https://www.santafe.gov.ar/hemerotecadigital/articulo/ellitoral/",
    },
    {
      id: "2",
      name: "Receptorías de avisos",
      src: "https://servicios.ellitoral.com/index.php/servicios/institucionales/receptorias.php",
    },
    {
      id: "3",
      name: "Administración y Publicidad",
      src: "https://servicios.ellitoral.com/seccion/contacto_diario",
    },
    {
      id: "4",
      name: "Elementos institucionales",
      src: "https://servicios.ellitoral.com/index.php/servicios/institucionales/terceros.php",
    },
    {
      id: "5",
      name: "Opcionales con El Litoral",
      src: "https://www.ellitoral.com/",
    },
    {
      id: "6",
      name: "MediaKit",
      src: "https://comercial.ellitoral.com",
    },
  ];

  return (
    <div className={`${styles["footer-container"]}`}>
      <a href="https://www.ellitoral.com/">
        <div className={`${styles["footer-logo"]}`}>
          <div>
            <Image 
              src={'/img/footer_logoellitoral.svg'} 
              alt={"El Litoral"}
              width={300}  
              className={`${styles['footer-logo-img']}`}
            />
          </div>
        </div>
      </a>

      <ul className={`${styles["footer-supplements"]}`}>
        {suplementsList.map((el) => (
          <li key={el.id}>
            <div className={`${styles["footer-supplements-item"]}`}>
              <a href={el.src} target={"_blank"} rel='noreferrer noopener'>{el.name}</a>
            </div>
          </li>
        ))}
      </ul>
      <div className={`${styles["columns-links-container"]}`}>
        <div/>

        <div className={`${styles["links-container"]}`}>
            <div className={`${styles["footer-social"]}`}>
              
                <a href="https://www.facebook.com/ellitoralcom" target={"_blank"} rel='noreferrer noopener'>
                  <div className={`${styles["footer-social-link"]} `}>
                  <Facebook
                    className={`${styles["icon"]} ${styles["facebook-icon"]}`}
                  />
                  </div>
                </a>
              

              
                <a href="https://twitter.com/ellitoral" target={"_blank"} rel='noreferrer noopener'>
                  <div className={`${styles["footer-social-link"]} `}>
                  <Twitter
                    className={`${styles["icon"]} ${styles["twitter-icon"]}`}
                  />
                  </div>
                </a>
              

              
                <a href="https://api.whatsapp.com/send/?phone=543426310642&text&app_absent=0" target={"_blank"} rel='noreferrer noopener'>
                  <div className={`${styles["footer-social-link"]} `}>
                  <Whatssap
                    className={`${styles["icon"]} `}
                  />
                  </div>
                </a>
              

              
                <a href="https://youtube.com/ellitoralcom" target={"_blank"} rel='noreferrer noopener'>
                  <div className={`${styles["footer-social-link"]} `}>
                  <YouTube
                    className={`${styles["icon"]} `}
                  />
                  </div>
                </a>
              

              
                <a href="https://www.instagram.com/ellitoral/" target={"_blank"} rel='noreferrer noopener'>
                  <div className={`${styles["footer-social-link"]} `}>
                  <Instagram
                    className={`${styles["icon"]} `}
                  />
                  </div>
                </a>
              

              
                <a href="https://servicios.ellitoral.com/seccion/contacto_diario" target={"_blank"} rel='noreferrer noopener'>
                  <div className={`${styles["footer-social-link"]} `}>
                  <Phone
                    className={`${styles["icon"]} `}
                  />
                  </div>
                </a>
              
            </div>
        </div>

        <div className={styles["footer-other-social"]}>
          <Image
            className={styles["footer-other-social-link"]}
            src={"/img/footer_meta.svg"}
            alt={"meta"}
            width={30}
            height={28}
          />
          <Image
            className={styles["footer-other-social-link"]}
            src={"/img/footer_google.svg"}
            alt={"google"}
            width={25}
            height={25}
          />
        </div>
      </div>

      <div className={`${styles['footer-links']}`}>
        {linksList.map((el, index) => (
          <Fragment key={'footerlinks'+index}>
            <span>{index ? ' - ' : ''}</span>
            <a className={`${styles['link-element']}`} href={el.src} target={"_blank"} rel='noreferrer noopener'>{el.name}</a>
          </Fragment>
        ))}
      </div>
      

      <div className={`${styles['footer-institutional']}`}>
        <div />
        <div className={`${styles['institutional-inner']}`}>
          <b>El Litoral es miembro de:</b>
          <div className={`${styles['brands']}`}>
            <a href="https://adepa.org.ar/" target={"_blank"} rel='noreferrer noopener'>
              <Image src={'/img/footer_adepa.jpg'} alt={"adepa.org.ar"} width={57} height={18} />
            </a>
            <a href="https://www.iab.com/" target={"_blank"} rel='noreferrer noopener'>
              <Image src={'/img/footer_iab_uap.jpg'} alt={"iab.com"} width={179} height={28} />
            </a>
            <a href="https://adira.org.ar/web/" target={"_blank"} rel='noreferrer noopener'>
              <Image src={'/img/footer_adira.png'} alt={"adira.org.ar"} width={65} height={19} />
            </a>
          </div>
        </div>
        <div className={`${styles['footer-institutional-afip']}`}>
          <a href="http://qr.afip.gob.ar/?qr=1-x3ssoSe2hgUc95rrCZWw,," title="Clave fiscal" target="_blank" rel='noreferrer noopener'>
            <Image src={'/img/footer_afip.jpg'} alt={"afip.gob.ar"} width={53} height={72} />
          </a>
        </div>
      </div>

      <div className={`${styles['footer-institutional']}`}>
        <div className={`${styles['institutional-inner']}`}>
          <b>En Asociación con:</b>
          <div className={`${styles['brands']}`}>
            <a href="https://www.lagaceta.com.ar/" target={"_blank"} rel='noreferrer noopener'>
              <Image 
                src={'/img/footer_la_gaceta.svg'} 
                alt={"la gaceta"} 
                width={122} 
                height={12}
              />
            </a>
            <a href="https://www.diariohuarpe.com/" target={"_blank"} rel='noreferrer noopener'>
              <Image 
                src={'/img/footer_huarpe.svg'} 
                alt={"diario huarpe"} 
                width={87} 
                height={15}
              />
            </a>
            <a href="https://www.eldia.com/" target={"_blank"} rel='noreferrer noopener'>
              <Image 
                src={'/img/footer_el_dia.png'} 
                alt={"diario el dia"} 
                width={81} 
                height={13} 
              />
            </a>
            <a href="https://www.rionegro.com.ar/" target={"_blank"} rel='noreferrer noopener'>
              <Image 
                src={'/img/footer_rio_negro.svg'} 
                alt={"diario rio negro"} 
                width={98} 
                height={15} 
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
